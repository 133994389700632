import React from 'react';

// import sections
import HeroSplit from '../components/sections/HeroSplit'
import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Cta from '../components/sections/Cta';


class Home extends React.Component {

  state = {
    videoModalActive: false
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {

    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />       
        <FeaturesSplit invertMobile topDivider imageFill />
        {/* <Testimonial topDivider />   */}
        {/* <Cta split /> */}
      </React.Fragment>
    );
  }
}

export default Home;